var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content groups-create"},[_c('ContentHeader',{attrs:{"title":_vm.isEditing ? _vm.formData.name : _vm.$t('groups.create:header.title'),"dark-theme":""}},[_c('Action',{staticClass:"btn-back",attrs:{"slot":"back","type":"button","text":_vm.$t('global:back.groups'),"icon":"keyboard_backspace"},on:{"click":function($event){return _vm.leave()}},slot:"back"}),_c('ActionBar',{attrs:{"slot":"actionbar"},slot:"actionbar"}),_c('template',{slot:"buttons"},[_c('Action',{attrs:{"text":_vm.$t('global:form.save'),"type":"button","flat":"","dark":""},on:{"click":function($event){return _vm.submit()}}})],1)],2),_c('div',{staticClass:"main-content-inner"},[_c('div',{staticClass:"center"},[_c('div',{staticClass:"groups-create-header"},[_c('h2',{staticClass:"groups-create-title"},[_vm._v(" "+_vm._s(_vm.$t('groups.create:header.title'))+" ")]),_c('p',{staticClass:"groups-create-description"},[_vm._v(" "+_vm._s(_vm.$t('groups.create:header.description'))+" ")])]),_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('InputField',{attrs:{"label":_vm.$t('global:form.groups.name'),"validation":_vm.$v.formData.name,"counter":50},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('FormSection',{attrs:{"title":_vm.$t('global:form.groups.show.portal')}},[_c('Radio',{attrs:{"items":[
              {
                label: 'Sim',
                value: true
              },
              {
                label: 'Não',
                value: false
              }
            ],"under-description":_vm.$t('global:form.groups.show.portal.description'),"horizontal":""},model:{value:(_vm.formData.showOnPortal),callback:function ($$v) {_vm.$set(_vm.formData, "showOnPortal", $$v)},expression:"formData.showOnPortal"}})],1),_c('FormSection',{attrs:{"title":_vm.TRANSLATION.description.description}},[_c('RichTextEditor',{staticClass:"rich-text",attrs:{"validation":_vm.$v.formData.description,"toolbar":_vm.RICH_TEXT_EDITOR_TOOLBAR},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }