<script>
import { app } from '@/main'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

const TRANSLATION = {
  description: {
    description: app.$t('global:form.groups.description')
  }
}

const RICH_TEXT_EDITOR_TOOLBAR = [
  [
    'bold',
    'italic',
    'underline',
    'strike'
  ],
  [ 'clean' ],
  [ 'link' ]
]

export default {
  name: 'GroupsManage',
  components: {
    Radio: () => import('@/components/general/Radio'),
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    RichTextEditor: () => import('@/components/general/RichTextEditor')
  },

  data () {
    return {
      formData: {
        id: null,
        name: null,
        description: null,
        showOnPortal: true
      },

      backUrl: { name: 'groups.index' }
    }
  },

  validations () {
    return {
      formData: {
        name: { required },
        description: { required }
      }
    }
  },

  computed: {
    isEditing () {
      return this.$route.params.id || this.formData.id
    }
  },

  created () {
    this.setup()

    if (this.$route.params.id) {
      this.setFetching(true)

      this.attemptGetGroup(this.$route.params.id)
        .then(({ data }) => {
          this.formData = {
            ...this.formData,
            ...data
          }
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('groups.load:fetch.error') })
          this.$router.push({ name: 'groups.index' })
        })
        .finally(() => {
          this.setFetching(false)
        })
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetGroup',
      'attemptCreateGroup',
      'attemptUpdateGroup'
    ]),

    formatFormData () {
      const data = {}

      data.id = this.formData.id
      data.name = this.formData.name
      data.description = this.formData.description
      data.show_on_portal = this.formData.showOnPortal

      return data
    },

    submitCreation () {
      this.setFetching(true)

      return this.attemptCreateGroup(this.formatFormData())
        .then(({ data }) => {
          this.formData.id = data.id
          this.setFeedback({ message: this.$t('groups:submit.create.success') })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitUpdate () {
      this.setFetching(true)

      return this.attemptUpdateGroup({
        id: this.formData.id,
        data: this.formatFormData()
      })
        .then(() => {
          this.setFeedback({ message: this.$t('groups:submit.update.success') })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submit () {
      return new Promise((resolve, reject) => {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          resolve(true)

          return this.isEditing ? this.submitUpdate() : this.submitCreation()
        } else {
          this.setFeedback({ message: this.$t('groups:feedback.validation.error') })
          reject(new Error('Error'))
        }
      })
    },

    leave () {
      this.modalConfirm = false

      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    setup () {
      this.TRANSLATION = TRANSLATION
      this.RICH_TEXT_EDITOR_TOOLBAR = RICH_TEXT_EDITOR_TOOLBAR
    }
  }
}
</script>

<template>
  <div class="main-content groups-create">
    <ContentHeader
      :title="isEditing ? formData.name : $t('groups.create:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.groups')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />

      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="submit()"
        />
      </template>
    </ContentHeader>

    <div class="main-content-inner">
      <div class="center">
        <div class="groups-create-header">
          <h2 class="groups-create-title">
            {{ $t('groups.create:header.title') }}
          </h2>

          <p class="groups-create-description">
            {{ $t('groups.create:header.description') }}
          </p>
        </div>

        <form
          class="form"
          @submit.prevent="submit()"
        >
          <InputField
            v-model="formData.name"
            :label="$t('global:form.groups.name')"
            :validation="$v.formData.name"
            :counter="50"
          />

          <FormSection :title="$t('global:form.groups.show.portal')">
            <Radio
              v-model="formData.showOnPortal"
              :items="[
                {
                  label: 'Sim',
                  value: true
                },
                {
                  label: 'Não',
                  value: false
                }
              ]"
              :under-description="$t('global:form.groups.show.portal.description')"
              horizontal
            />
          </FormSection>

          <FormSection :title="TRANSLATION.description.description">
            <RichTextEditor
              v-model="formData.description"
              :validation="$v.formData.description"
              :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
              class="rich-text"
            />
          </FormSection>
        </form>
      </div>
    </div>
  </div>
</template>

<style>
.groups-create .form {
  max-width: 480px;
  margin: 0 auto;
}
.groups-create-header {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.groups-create-title {
  font-family: 'Quicksand';
  font-size: 2.4em;
  font-weight: bold;
  color: #005EB8;
  line-height: 30px;
  margin-bottom: 5px;
}

.groups-create-description {
  font-family: 'Quicksand';
  text-transform: initial;
  margin: 16px auto 0;
  line-height: 1.5em;
  font-style: italic;
  max-width: 640px;
  font-size: 1.6em;
  color: #666;
}
.link-selection,
.link-selection .form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-selection .link-table > div {
  width: 50%;
}

.link-table {
  display: flex;
  flex-direction: row;
}

.groups-create-header + .center .tabs {
  display: flex;
  justify-content: center;
  margin: 65px 0 80px;
}

.under-description {
  display: inline-block;
  font-size: 1.2em;
  color: rgba(0,0,0,0.5);
  padding: 5px 0;
  max-width: 480px;
  text-transform: none;
}

.hex-under-description {
  margin-bottom: 10px;
}

</style>
